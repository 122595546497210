import React, { useEffect } from "react";
import { useRouter, withRouter } from "next/router";
import ReactGA_4 from "react-ga4";
import dynamic from "next/dynamic";
import { connect } from "react-redux";
import ability from "@utils/ability";
import useUser from "@hooks/use_user";
import HorizontalAd from "@components/HorizontalAd/HorizontalAd";
import { FlexContainer } from "@components/ui/flex_container";

const Page = dynamic(() => import("@components/layout/page"));
const FrontPage = dynamic(() => import("@components/front_page/front_page"));
const SearchResults = dynamic(() => import("@components/front_page/search_results_container"));

function TorgidPage() {
    const user = useUser();
    const router = useRouter();

    React.useEffect(() => {
        ReactGA_4.send("pageview");
    }, []);

    const [startedSearching, setStartedSearching] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState("");

    useEffect(() => {
        if (router.query.q) {
            setSearchQuery(router.query.q);
            setStartedSearching(true);
        }
    }, [router.query.q]);

    return (
        <>
            {router.query.q ? (
                <Page showsSearchBar showsFooter={false}>
                    <SearchResults query={router.query.q} />
                </Page>
            ) : (
                <>
                    {!startedSearching ? (
                        <Page>
                            <FrontPage
                                onSearchBarChange={(query) => {
                                    // if (ability(user).cannot("manage", "registration")) {
                                    //     return;
                                    // }
                                    setSearchQuery(query);
                                    setStartedSearching(true);
                                }}
                            />
                        </Page>
                    ) : (
                        <Page
                            showsSearchBar={startedSearching}
                            searchQuery={searchQuery}
                            showsFooter={false}
                        >
                            <FlexContainer
                                style={{
                                    flexGrow: 1,
                                }}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <div style={{ height: "50vh", minHeight: "650px" }} />
                                <HorizontalAd
                                    adPosition={1}
                                    style={{ justifySelf: "center", alignSelf: "center" }}
                                />
                            </FlexContainer>
                        </Page>
                    )}
                </>
            )}
        </>
    );
}

const mapState = (state) => {
    return {
        name: state.name,
    };
};

const mapDis = () => {
    return {
        callAction: () => {},
    };
};

export default connect(mapState, mapDis)(withRouter(TorgidPage));
